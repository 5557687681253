<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view />
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color: #2c3e50;
}

h1 {
  font-size: 40px;
  line-height: 45px;
  text-transform: uppercase;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  text-shadow: 1px 1px #000;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #c20e1a;
}

nav {
  position: fixed;
  width: 100%;
  padding: 30px;
  bottom: 0;

  a {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-decoration: none;

    &:visited {
      color: #fff;
    }
    &:hover,
    &.router-link-exact-active {
      color: #c20e1a;
    }
  }
}
</style>
